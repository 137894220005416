import cssVars from 'css-vars-ponyfill'
import hexRgb from 'hex-rgb'
import stickElement from '../util/stickit'
import 'jquery-match-height/dist/jquery.matchHeight-min'

const dhsvCommon = {
  hexToRgb(hex) {
    const rgb = hexRgb(hex, { format: 'array' })
    rgb.pop()
    return rgb.join(',')
  },
  setCssVars() {
    let themeColor = '#d21317'
    let themeColorRGB = this.hexToRgb('#d21317')
    let themeColorDark = '#891721'
    let themeColorDarkRGB = this.hexToRgb('#891721')
    let choosenTheme = $('body').attr('data-color')
    if (choosenTheme == 'yellow') {
      themeColor = '#f7b800'
      themeColorRGB = this.hexToRgb('#f7b800')
      themeColorDark = '#bd7f00'
      themeColorDarkRGB = this.hexToRgb('#bd7f00')
    } else if (choosenTheme == 'blue') {
      themeColor = '#6fa0c0'
      themeColorRGB = this.hexToRgb('#6fa0c0')
      themeColorDark = '#3b78a6'
      themeColorDarkRGB = this.hexToRgb('#3b78a6')
    } else if (choosenTheme == 'green') {
      themeColor = '#74ba5d'
      themeColorRGB = this.hexToRgb('#74ba5d')
      themeColorDark = '#4c7e56'
      themeColorDarkRGB = this.hexToRgb('#4c7e56')
    } else if (choosenTheme == 'orange') {
      themeColor = '#f18229'
      themeColorRGB = this.hexToRgb('#f18229')
      themeColorDark = '#ac511a'
      themeColorDarkRGB = this.hexToRgb('#ac511a')
    } else if (choosenTheme == 'türkis') {
      themeColor = '#57b2af'
      themeColorRGB = this.hexToRgb('#57b2af')
      themeColorDark = '#347985'
      themeColorDarkRGB = this.hexToRgb('#347985')
    } else if (choosenTheme == 'brombeer') {
      themeColor = '#af5080'
      themeColorRGB = this.hexToRgb('#af5080')
      themeColorDark = '#75225e'
      themeColorDarkRGB = this.hexToRgb('#75225e')
    } else if (choosenTheme == 'darkGreen') {
      themeColor = '#78B964'
      themeColorRGB = this.hexToRgb('#78B964')
      themeColorDark = '#78B964'
      themeColorDarkRGB = this.hexToRgb('#78B964')
    } else if (choosenTheme == 'aph_single_blue') {
      themeColor = '#5bc0cb'
      themeColorRGB = this.hexToRgb('#5bc0cb')
      themeColorDark = '#5bc0cb'
      themeColorDarkRGB = this.hexToRgb('#5bc0cb')
    } else if (choosenTheme == 'aph_single_red') {
      themeColor = '#C62222'
      themeColorRGB = this.hexToRgb('#C62222')
      themeColorDark = '#C62222'
      themeColorDarkRGB = this.hexToRgb('#C62222')
    } else if (choosenTheme == 'aph_single_yellow') {
      themeColor = '#FDC649'
      themeColorRGB = this.hexToRgb('#FDC649')
      themeColorDark = '#FDC649'
      themeColorDarkRGB = this.hexToRgb('#FDC649')
    }

    console.log(choosenTheme, 'THEME')

    cssVars({
      variables: {
        '--body-width': document.body.clientWidth + 'px',
        '--col-width': $('.wrap').outerWidth() / 12 + 'px',
        '--primary-color': themeColor,
        '--primary-color-rgb': themeColorRGB,
        '--secondary-color': themeColorDark,
        '--secondary-color-rgb': themeColorDarkRGB,
      },
    })
  },
  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 112
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  gfieldButtonsRemove() {
    $('.gform_wrapper').each(function () {
      if ($(this).find('.gfield .btn').length > 0) {
        $(this).find('.gform_footer').css({
          position: 'absolute',
          left: '-9999px',
        })
      }
    })
  },
  focus_gfSelect() {
    $('.ginput_container_select .gfield_select').on('focus blur', function () {
      $(this).parents('.ginput_container_select').toggleClass('active')
    })
    $('.ginput_container_select .gfield_select')
      .on('change', function () {
        let selected = $(this).find(
          'option:selected:not([selected="selected"])'
        )
        if (selected.length > 0) {
          $(this).parent().addClass('selected')
        } else {
          $(this).parent().removeClass('selected')
        }
      })
      .trigger('change')
  },
  init() {
    this.gfieldButtonsRemove()
    this.setCssVars()

    if ($('.number_input').length) {
      let $phoneInput = $('.number_input').find('input')
      this.setInputFilter($phoneInput, function (value) {
        return /^\d*$/.test(value) // Allow digits only, using a RegExp
      })
    }

    this.focus_gfSelect()

    //Resize height of textarea on input
    $('.gfield textarea').each(function () {
      $(this).on('input', function () {
        $(this).css('height', '0px')
        $(this).css('height', this.scrollHeight + 'px')
      })
    })

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )

    $('.matchHeight').matchHeight()

    //remove click class at ,,Ausschreibungstabelle"
    /*$('#wlp_3c84897968f1c483703150e47fdfe99e_all table tbody tr').removeClass(
      'click'
    )*/

    $(document).on('gform_post_render', function () {
      setTimeout(function () {
        dhsvCommon.gfieldButtonsRemove()
      }, 500)
    })

    //Open New window on share
    $('.share-button').click(function (e) {
      e.preventDefault()
      var wpWidth = $(window).width()
      var wpHeight = $(window).height()
      window.open(
        $(this).attr('href'),
        'Share',
        'top=' +
          (wpHeight - 400) / 2 +
          ',left=' +
          (wpWidth - 600) / 2 +
          ',width=600,height=400'
      )
    })

    // burger menu
    const $burgerMenu = $('.js-burger-nav')
    $('.js-burger-nav-handler').on('click', function (e) {
      e.preventDefault()
      $(this).toggleClass('is-active')
      $('html').toggleClass('menu-opened')
      let delay = 0.2
      $burgerMenu.find('.nav > .menu-item').each((i, el) => {
        $(el).css({
          transitionDelay: delay + 's',
        })
        delay += 0.1
      })

      $burgerMenu.find('.login_link').css({
        transitionDelay: delay + 's',
      })

      delay += 0.1

      $burgerMenu.find('.market_button').css({
        transitionDelay: delay + 's',
      })

      let $minusValue = $('.banner').outerHeight()
      $burgerMenu
        .css({
          height: `calc(100vh - ${$minusValue}px)`,
        })
        .toggleClass('is-active')
        .closest('.banner')
        .toggleClass('is-active')
    })

    /* Change theme contrast */

    $('.contrast').on('click', function () {
      $('html').toggleClass('with_contrast')
    })
  },
  onResize() {
    $(window).on('resize', () => {
      this.setCssVars()
    })
  },
  onLoad() {
    $(window).on('load', () => {
      var teaser = $('.teaser_slider')
      if (teaser.length != 0) {
        teaser.each(function () {
          var $this = $(this)
          var slides = $(this).find('.teaser_slider_thumbnail .swiper-slide')
            .length
          var dots = $(this).find('.posts-pagination .swiper-pagination-bullet')
            .length
          if (dots != slides) {
            $(this).find('.posts-pagination').empty()
            var itemActive =
              '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabIndex="0" role="button" aria-label="Go to slide 2"></span>'
            var item =
              '<span class="swiper-pagination-bullet" tabIndex="0" role="button" aria-label="Go to slide 2"></span>'
            for (var i = 0; i < slides; i++) {
              if (i === 0) {
                $(this).find('.posts-pagination').append(itemActive)
              } else {
                $(this).find('.posts-pagination').append(item)
              }
            }

            $('.swiper-pagination-bullet').on('click', function () {
              $('.swiper-pagination-bullet').removeClass(
                'swiper-pagination-bullet-active'
              )
              $(this).addClass('swiper-pagination-bullet-active')
            })

            $(document)
              .find('.slider_navigation .post-next')
              .on('click', function () {
                $('.swiper-container-thumbs .swiper-slide').each(function (
                  index
                ) {
                  if ($(this).hasClass('swiper-slide-active')) {
                    console.log(index)
                    if (index === 0) {
                      $('.swiper-pagination-bullet:last-of-type').removeClass(
                        'swiper-pagination-bullet-active'
                      )
                    }
                    $this
                      .find('.posts-pagination .swiper-pagination-bullet')
                      .each(function (i) {
                        if (i === index) {
                          $(this).addClass('swiper-pagination-bullet-active')
                          $(this)
                            .prev()
                            .removeClass('swiper-pagination-bullet-active')
                        }
                      })
                  }
                })
              })
          }
        })
      }
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onLoad()
    dhsvCommon.onResize()
    gform_11_init()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    stickElement('header.banner')
  },
}

const gform_11_init = () => {
  if (document.getElementById('gform_11')) {
    gform_11_summary()
    jQuery(document).bind('gform_page_loaded', gform_11_summary)
  }
}

const gform_11_summary = () => {
  console.log('change step') //eslint-disable-line
  document.getElementById(
    'gform_page_11_3'
  ).innerHTML = document
    .getElementById('gform_page_11_3')
    .innerHTML.replace(/\[fieldvalue;field_11_(\d+)\]/g, match => {
      const id = match.replace('[fieldvalue;field_', '').replace(']', '')
      const field = document.getElementById('field_' + id)

      if (!field) {
        return ''
      }

      const type = getType(field)

      console.log(type) //eslint-disable-line

      switch (type) {
        case 'radio':
          return getGformRadio(id)
        default:
          return document.getElementById('input_' + id).value
      }
    })
}

const getType = field => {
  const container = field ? field.querySelector('.ginput_container') : false
  if (!container) {
    return 'text'
  }
  switch (true) {
    case container.classList.contains('ginput_container_date'):
      return 'date'
    case container.classList.contains('ginput_container_radio'):
      return 'radio'
    default:
      return 'text'
  }
}

const getGformRadio = id => {
  const boxes = document.querySelectorAll(
    `#input_${id} input[name="input_${id.replace('11_', '')}"]`
  )

  let selectedValue
  for (const rb of boxes) {
    if (rb.checked) {
      selectedValue = rb.value
      break
    }
  }
  return selectedValue
}

/*
const getGformDate = id => {
  const fieldID = 'input_' + id
  const fields = {
    day: document.getElementById(fieldID + '_2').value,
    month: document.getElementById(fieldID + '_1').value,
    year: document.getElementById(fieldID + '_3').value,
  }

  return Object.values(fields).join('.')
}
*/
